.sidebar-main {
  margin-top: 1rem;
}

.sidebar .accordion-flush>.accordion-item>.accordion-collapse {
  visibility: visible !important;
}

.toggleSidebar {
  .sidebar {
    .accordion-collapse.collapse {
      height: auto !important;
      transition: all 0.3s;
      visibility: visible !important;

      &.show {
        position: absolute;
        left: 70px;
        border-radius: 10px;
      }
    }
  }

  .sidebar-submenu {
    margin-bottom: 0px;
  }

  .sidebar {
    &:after {
      width: 0px !important;
    }

    .logo-wrapper {
      padding: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .lgLogo {
      display: none !important;
    }

    .smLogo {
      display: block !important;

      img {
        width: 45px;
        height: 45px;
        object-fit: contain;
      }
    }

    .sidebar-list {
      padding: 0px 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;

        em {
          margin: 0;
        }
      }

      em {
        &+span {
          width: 0px;
        }
      }
    }

    width: 70px;

    &:after {
      width: 70px;
    }

    .accordion-button {
      &:after {
        display: none;
      }
    }
  }
}

.sidebar.text-dark {
  &:after {
    // filter: invert(0.5);
  }

  .accordion-item {
    * {
      // color: color(900);
      text-shadow: none !important;
    }

    // filter: invert(1);
    svg {
      color: color(900) !important;
    }
  }
}

.sidebar {
  background: linear-gradient(-45deg, rgba(70, 107, 255, 0.86), #1e2f97);
  color: white;

  svg {
    path[fill]:not(path[fill="none"]) {
      fill: currentColor !important;
    }

    path[stroke]:not(path[stroke="none"]) {
      stroke: currentColor !important;
    }
  }

  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 250px;
    top: 0;
    left: 0;
    position: fixed;
    // background: url(../../../../public/assets/images/pattern.svg);
    // background-size: 320px;
    filter: invert(1);
    z-index: -1;
    opacity: 0.1;
  }

  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10000000;
  overflow: hidden;
  line-height: inherit;
  min-width: 250px;
  text-align: left;
  @include transition(all 0.4s);
  // -webkit-box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  // box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  height: 100vh;

  &:hover {
    overflow: auto;
  }

  .sideBarToggle_ {
    display: block;
    position: absolute;
    right: 10px;
  }

  a.lan-5 {
    padding-left: 10px;
    font-weight: normal !important;
    font-size: 0.75rem;
    border-left: 3px solid rgba(color(50), 0.3);
    margin-left: 25px;
  }

  .list-group {
    &-item {
      padding: 0px;
    }
  }

  .logo-wrapper {
    padding: 20px;
    display: flex;
    align-items: center;

    .sideBarToggle_ {
      margin-left: auto;
      margin-right: 0px;
      color: color(50);
    }

    .smLogo {
      display: none;
    }

    .lgLogo {
      display: block;

      img {
        max-width: 100%;
        height: auto;
        max-height: 55px;
      }
    }

    a {
      padding: 0;
    }

  
  }

  em {
    margin-right: 0.85em;
    font-size: 1.1em;
  }

  .no-dropdown {

    .accordion-button:not(.collapsed)::after,
    .accordion-button::after {
      display: none;
    }
  }

  &-list {
    display: block;
    width: 100%;
    // padding: 0 20px;
    position: relative;
    font-family: font(md);
    border-width: 0px !important;

    .accordion-body {
      padding: 0;
    }

    .accordion-header>button {
      box-shadow: none;
      border-radius: 10px !important;
      border-width: 0px !important;
      opacity: 0.75;
      @include transition(all 0.5s);
      position: relative;
      margin-bottom: 0px;
      padding: 12px 15px;
      display: flex !important;
      align-items: center;
      font-size: 1em;
      overflow: hidden;
      color: white;

      line-height: 19px;

      &>div {
        display: flex;
        align-items: center;
      }

      img {
        height: 1.4em;
        width: 1.4em;
        object-fit: contain;
        // filter: invert(1);
      }

      * {
        pointer-events: none;
      }

      span {
        display: inline-block;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: color(100);
        // color: color(50);
        opacity: 1;
      }

      &.active {
        background-color: color(100);
        color: color(220);
      }

      &:after {
        height: 1.01em;
        width: 1.01em;
        background-size: contain;
        color: white;
        filter: invert(1);
      }
    }
  }

  &-submenu {
    width: 100%;
    position: relative !important;
    list-style: none;
    padding: 0px;
    @include transition(all 0.4s);
    transform-origin: top;
    font-size: 1em;
    color: white;

    a {
      // color: color(50);
      display: block;
      position: relative;
      letter-spacing: 0.06em;
      font-weight: 500;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 7px 25px;
      background: rgba(color(900), 0.1);

      li {
        a {
          color: color(150);

          img {
            filter: invert(1);
          }

          &.active {
            color: color(220);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    position: absolute !important;
  }

  .working {
    pointer-events: auto !important;
    opacity: 1 !important;
  }

  .accordion-item {
    pointer-events: none;
    background: transparent !important;
    opacity: 0.5;
  }

  // .accordion-button {
  //     svg {
  //         // fill: color(50) !important;

  //         &>* {
  //             stroke: color(50);
  //         }
  //     }
  // }

  .accordion-button,
  .accordion-button:not(.collapsed) {
    background: transparent !important;
    // color: color(50);
  }

  .accordion-button::after {
    // filter: invert(1) brightness(10);
  }
}

.toggleSidebar {
  .sidebar {
    min-width: 0px;

    &-submenu {
      background: rgba(color(230), 0.8);
    }
  }
}

@include media-breakpoint-up(xl) {
  .sideBarToggle_ {
    display: none;
  }

  .sideBarToggle_ {
    display: none !important;
  }
}

@include media-breakpoint-down(xl) {
  .sideBarToggle_ {
    display: block;
  }

  .toggleSidebar {
    .sideBarToggle_ {
      display: none !important;
    }
  }
}

@include media-breakpoint-down(xl) {
  .lgLogo {
    margin-left: 50px;
    margin-top:10px
  }
}