/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Teko:wght@300..700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

/* Custom Scrollbar for WebKit (Chrome, Safari) */
.smash-home ::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.smash-home ::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.smash-home ::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Roundness of the scrollbar handle */
  border: 3px solid #f1f1f1; /* Adds space around the handle */
}

.smash-home ::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color when hovering over the scrollbar handle */
}

.smash-home body {
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Inter", sans-serif;
}

.smash-home * {
  /* Custom Scrollbar for Firefox */
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Handle color and track color */
}
.smash-home label,
textarea,
input,
select,
.btn,
p {
  font-size: 14px !important;
}
label {
  font-weight: bold;
}
.headingFont {
  font-size: 30px;
  font-weight: 900;

  /* font-family: "Lobster Two", sans-serif !important; */
  /* font-family: "Teko", sans-serif; */
}
.slick-slide img {
  display: block;
  width: 100%;
  height: auto;
}
nav.nav-bar {
  transition: all 0.3s;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

  padding: 30px !important;
  background: transparent;
}
nav.nav-bar.scrolled {
  padding: 10px 15px !important;
  /* From https://css.glass */
  /* From https://css.glass */
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.scrolled .navbar-brand {
  /* filter: invert(1); */
}
@keyframes slideIn {
  0% {
    transform: translateX(-200%) rotate(30deg); /* Starts off-screen to the left */
    opacity: 0;
  }
  100% {
    transform: rotate(30deg) translate(var(--x), var(--y)); /* Final position */
    opacity: var(--final-opacity); /* Final opacity for each shape */
  }
}

.purpleCapsule {
  height: 500px;
  width: 200px;
  border-radius: 200px;
  background: #445bef !important;
  position: absolute;
  top: 0%;
  left: 0;
  opacity: 0; /* Start with opacity 0 */
  animation: slideIn 1.5s forwards ease-in-out;
}

.purpleCapsule.shape1 {
  --x: 0px;
  --y: 250px;
  --final-opacity: 1; /* Full opacity */
  animation-delay: 0.3s;
}

.purpleCapsule.shape2 {
  --x: 80px;
  --y: 410px;
  --final-opacity: 0.5; /* 50% opacity */
  animation-delay: 0.6s;
}

.purpleCapsule.shape3 {
  --x: 150px;
  --y: 530px;
  --final-opacity: 0.5; /* 50% opacity */
  animation-delay: 0.9s;
}

.sectionHeading {
  font-weight: 900;
}
.slider-line {
  position: absolute;
  z-index: 2000;
  right: 0;
  top: -40%;
  /* width: 12%; */
}
.slider-line2 {
  position: absolute;
  top: 0px;
  z-index: 1;
  left: -70px;
  width: 30%;
}
.image-slider {
  height: 100vh;
  overflow: hidden;
}

.image-slider::before {
  content: "";
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.7) 50%);
  background-size: 3px 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.slider-content {
  position: relative;
  z-index: 2;
}
.image-slider video {
  height: 100%;
  object-fit: cover;
}
.slider-btn {
  display: inline-block;
  position: relative;
}
.bg-theme {
  background: #ded52d;
}
.text-theme {
  color: #445bef;
  font-weight: 600;
}
.btn {
  transition: all 0.5s 0.3s;
}
.btn-theme {
  background: #000 !important;
  color: white !important;
}
.customBtn {
  animation-delay: 0.4s;

  text-decoration: none;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  padding: 20px 30px;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 8;
  text-transform: capitalize;
  background: #445bef;
  border: none;
  font-weight: 500;
}
.customBtn {
  border: 2px solid #445bef;
}
.customBtn.active {
  background: black;
  color: #fff;
  border: 1px solid #445bef;
  box-shadow: none;
}
.customBtn:hover {
  color: white;
  background: #445bef;
}
.mt-30 {
  margin-top: 30px;
}
.mr-15 {
  margin-right: 15px;
}
.slider-content h2 {
  font-size: 3vw;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #fff;
  text-transform: capitalize;
}
.s-slider-content p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.922);
  line-height: 26px;
  margin: auto;
}
.slider-active .slick-arrow,
.slider-active .slick-next {
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.slick-prev {
  z-index: 100 !important;
}
.slick-next {
  z-index: 100 !important;
}
.slider-active .slick-arrow {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 5%;
  color: #146b95;
  font-size: 26px;
  line-height: 60px;
  border: none;
  text-align: center;
  z-index: 9;
  cursor: pointer;
  padding: 0;
  background: none;
  transition: 0.5s;
  width: 60px;
  height: 60px;
  background: #fff;
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.slider-active .slick-arrow,
.slider-active .slick-next {
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.slider-active .slick-arrow:hover {
  color: #fff;
  background: #000;
}
.slider-active .slick-next {
  right: 5%;
  left: inherit;
}
.slider-active:hover .slick-prev {
  left: 4%;
}
.slider-active:hover .slick-arrow,
.slider-active:hover .slick-next {
  opacity: 1;
}
.transition_card img {
  transition: all 0.3s;
}
.transition_card:hover img {
  transform: scale(1.3);
}
.program_card .sectionInfo {
  transition: all 0.5s;
  color: white;
  background: rgb(33, 37, 41);
  background: -moz-linear-gradient(0deg, rgba(33, 37, 41, 0.9) 0%, rgba(33, 37, 41, 0) 90%);
  background: -webkit-linear-gradient(0deg, rgba(33, 37, 41, 0.9) 0%, rgba(33, 37, 41, 0) 90%);
  background: linear-gradient(0deg, rgba(33, 37, 41, 0.9) 0%, rgba(33, 37, 41, 0) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  padding: 50% 15px 15px;
  position: absolute;
  bottom: -10%;
  width: 100%;
  left: 0;
  opacity: 0;
}
.program_card:hover .sectionInfo {
  bottom: 0;
  opacity: 1;
}

.program_card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}
.smash-home footer {
  background-color: rgb(0, 0, 0);
  position: relative;
}
.footerBg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
  background: url("../../../public/images/footer_top.png");
  height: 5.4vw;
  top: -5.3vw;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Custom sidebar styles */
.custom-sidebar {
  position: fixed;
  top: 0;
  right: -100%; /* Hidden by default */
  width: 350px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: white;
  transition: right 0.4s ease-in-out;
  z-index: 100000;
  padding: 20px;
  text-align: center;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.custom-sidebar.open {
  right: 0;
}
.custom-sidebar a {
  font-size: 20px;
  display: inline-block;
  color: white;
  text-decoration: none;
  transition: all 0.3s;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.custom-sidebar a:after {
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 75%;
  width: 0%;
  background-color: #ffffff;
  left: 50%;
  transition: all 0.3s;
}
.custom-sidebar a:hover {
  color: #ffffff;
}
.custom-sidebar a:hover:after {
  width: 100%;
  left: 0%;
}
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-header h2 {
  margin: 0;
  color: white;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  top: 5%;
  position: fixed;
  right: 3%;
  font-size: 40px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 999;
  cursor: pointer;
}

.testimonial_card {
  transition: all 0.5s;
}

.testimonial_bg {
  background-repeat: no-repeat !important;
}

.ReactModalPortal {
  z-index: 10000;
  position: relative;
}
.overlayBg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  font-size: 50px;
  opacity: 0;
  transition: all 0.3s;
}
.overlayBg:hover {
  opacity: 1;
}
.img_frame {
  border-radius: 15px;
  position: absolute;
}
.img_frame_1 {
  border: 5px solid #445bef;
  top: 0px;
  left: 100px;
}
.img_frame_2 {
  border: 3px solid #ec44ef;
  top: 140px;
  left: 80px;
}
.img_frame_3 {
  border: 6px solid #efb344;
  top: 100px;
  left: 0px;
}
.img_frame_4 {
  border: 1px solid #ef8644;
  top: 100px;
  left: 200px;
}
.bgWave {
  background: url("../../../public/images/playerWaveBg.jpg");
  background-size: cover;
  position: relative;
  min-height: 700px;
  display: flex;
  align-items: center;
}
.bgWave:after {
  background: url("../../../public/images/wavebg.png");
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100%;
  height: 3.55vw;
  width: 100%;
}

.bgWave:before {
  background: url("../../../public/images/wavebg.png");
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: 100%;
  height: 3.55vw;
  width: 100%;
  background-position: bottom;
}
.mainChar {
  position: absolute;
  top: 0;
  right: 0;
  width: 650px;
}
.sectionStoryShaps {
  position: absolute;
  top: 0;
  right: 0;
}
.desktopView {
  display: flex;
  gap: 12px;
}
.togglenav {
  display: none;
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 1200px) {
  .mainChar {
    width: 40%;
  }
}

@media only screen and (max-width: 993px) {
  .headingFont {
    font-size: 25px;
  }
  nav.nav-bar .container {
    padding: 0;
  }
  nav.nav-bar {
    padding: 10px !important;
  }
  .desktopView {
    display: none !important;
  }
  .togglenav {
    display: flex;
  }
  .mainChar {
    display: none;
  }
  .smash-home .h2,
  h2 {
    font-size: 2rem;
  }

  .slider-content h2 {
    margin-top: 15px;
    font-size: 4vw;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .slider-line2 img {
    display: none;
  }
  .slider-content h2 {
    font-size: 7vw;
    margin-bottom: 20px;
  }
}
