.notfound {
  // perspective: 100px;
}

.grid-stack-item-content.auto-height {
  height: auto !important;
}

.grid-stack-item {
  grid-auto-rows: min-content;
}

.grid-stack-item {
  height: auto;
}

span.cropper-point,
.cropper-line {
  pointer-events: none;
}

body.iFrame * {
  pointer-events: none;
}

.App[style="zoom: 0.8;"] {
  pointer-events: none;
}

.svgImg_ {
  svg {
    height: 30px;
    width: 30px;
  }
}

div#tsparticles {
  position: absolute;
  height: 50vh;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;
}

.tipsHolder {
  width: 300px;
  border-radius: 10px;
  color: #fff;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000000;
  box-shadow: 0px 0px 20px 0px rgba(color(900), 0.3);

  p {
    margin-bottom: 0;
  }

  &__description {
    padding: 0 10px;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: color(50);
    padding: 10px;

    h6 {
      font-weight: bold;
      padding: 0px !important;
    }
  }

  button.btn-close {
    filter: invert(1);
  }

  .slick-dots {
    filter: invert(1);
    margin-bottom: 16px;
    margin-top: 5px;

    li {
      margin: 0;
    }
  }

  .slick-slider {
    margin-bottom: 0px;
  }
}

.tipsHolder .slick-dots li button:before {
  height: 5px !important;
  width: 5px !important;
  background-color: #000000 !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}
code {
  font-size: 14px;
  font-weight: bold;
  padding: 2px 7px;
  border-radius: 5px;
}
.normalize_text,
.normalize_text * {
  font-size: inherit;
  font-weight: normal;
}
.socialMedia.variant1 {
  right: 0;
  // height: 100px;
  flex-direction: column;
  display: flex;
  box-shadow: none;
  align-items: center;
  justify-content: space-around;
  background: transparent !important;
  height: auto;
  padding: 15px;
  .rootBtn {
    padding: 10px;
    border: 2px solid var(--base-color);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }

  a {
    transition: all 0.3s;
    // position:absolute;
    // bottom: 0;
    right: 0;
    padding: 5px;
    background: #fff;
    border-radius: 50px;
  }
  span {
    display: none;
  }
}
.socialMedia {
  position: fixed;
  background: #fff;
  padding: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px 0px 0px 10px;
  right: -80px;
  bottom: 10%;
  transition: all 0.3s;
  z-index: 100000;
  a[href="#"] {
    // filter: grayscale(1);
    // opacity: .8;
    // pointer-events: none;
  }
}

.socialMedia a {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.socialMedia a:first-child {
  margin-top: 0px;
}

.socialMedia a:last-child {
  margin-bottom: 0px;
}

.socialMedia img {
  height: 25px;
  width: 25px;
  transition: all 0.3s;
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// .loader {
//     -webkit-animation: rotating 2s linear infinite;
//     -moz-animation: rotating 2s linear infinite;
//     -ms-animation: rotating 2s linear infinite;
//     -o-animation: rotating 2s linear infinite;
//     animation: rotating 2s linear infinite;
// }

// .cropper-hidden {
//     opacity: 1 !important;
// }

.homelink {
  position: absolute;
  color:white !important;
  top: 20px;
  left: 20px;
  z-index: 10000;
}

.changeView {
  position: absolute;
  right: 15px;

  button {
    margin-left: 10px;
  }
}
