:focus {
    outline: 0 !important;
}

.hideScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.hideScroll::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

::-moz-selection {
    color: color(50);
    background: color(220);
}

::selection {
    color: color(50);
    background: color(220);
}

.modalWrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    @include media-breakpoint-down(lg) {
        width: 4px;
        height: 4px;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: rgba(color(110), 0);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
    background-color: #9aa2a9;
}